body, html {
  margin: 0;
  padding: 0;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: 900;
  color: white;
  text-shadow: 
    -1px -1px 0 #c51c1c,
    1px -1px 0 #c51c1c,
    -1px 1px 0 #c51c1c,
    1px 1px 0 #c51c1c;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.App {
  text-align: center;
  font-family: 'Orbitron', Arial, sans-serif;
}

header {
  color: white;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

nav ul li {
  margin: 0.5rem;
}

nav ul li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-family: 'Audiowide', cursive;
  font-size: 1rem;
}

.main-title {
  font-size: 6rem;
  margin-bottom: 2rem;
  margin: auto;
  margin-top: 15vh;
  width: 100%;
  padding: 1rem;
  font-size: 4rem;
  background-color: black;
}

.contract-address {
  font-size: 1.2rem;
  font-family: 'Russo One', sans-serif;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60vh;
}

.flashing-text-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.flashing-text {
  font-size: 24vw;
  font-weight: bold;
  font-family: 'Orbitron', sans-serif;
  animation: flash 1s linear infinite, colorChange 0.5s linear infinite;
}
.click-prompt {
  font-family: 'Racing Sans One', cursive;
  font-size: 1.5rem;
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  position: fixed;
  top: 14vh;
  right: 10vw;
  animation: bounce 0.3s infinite alternate, glitch 0.2s infinite, rotate 2s infinite linear;
  transform-origin: center;
}

@keyframes bounce {
  from {
    transform: translateY(0) scale(1);
  }
  to {
    transform: translateY(-20px) scale(1.1);
  }
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  10% {
    transform: translate(-5px, 5px);
  }
  20% {
    transform: translate(-5px, -5px);
  }
  30% {
    transform: translate(5px, 5px);
  }
  40% {
    transform: translate(5px, -5px);
  }
  50% {
    transform: translate(-5px, 5px);
  }
  60% {
    transform: translate(-5px, -5px);
  }
  70% {
    transform: translate(5px, 5px);
  }
  80% {
    transform: translate(5px, -5px);
  }
  90% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0);
  }
}

.click-prompt:hover {
  animation-play-state: paused;
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

@keyframes colorChange {
  0% { color: red; }
  16% { color: orange; }
  32% { color: yellow; }
  48% { color: green; }
  64% { color: blue; }
  80% { color: indigo; }
  100% { color: violet; }
}

@media (max-width: 768px) {
  .main-title {
    font-size: 3rem;
  }

  .contract-address {
    font-size: 1rem;
    bottom: 1rem;
  }

  nav ul li a {
    font-size: 0.9rem;
  }
}


@media (max-width: 480px) {
  .main-title {
    max-width: 480px;
    padding:0%;
    margin-top: 25vh;
  }

  .contract-address {
    font-size: 0.8rem;
    margin-top: 41vh;
    
  }

  nav ul li a {
    font-size: 0.8rem;
  }

  .flashing-text-container {
    flex-direction: column;
    justify-content: space-around;
  }

  .flashing-text {
    font-size: 15vw;
  }
}