.imageGallery {
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    position: fixed;
    top: 1vh;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media (max-width: 768px) {
        .imageGallery {
            background-size: 100vw;
        }
}

@media (max-width: 480px) {
    .imageGallery {
        background-size: 100vw;
    }
}